


































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindLojaUseCase, SaveMarcaUseCase } from '@/usecases'
import {  obrigatorio } from '@/shareds/regras-de-form'
import SeletorDeLojas from '../loja/SeletorDeLojas.vue'
import { Loja } from '@/models/Loja'
import { Marca } from '@/models'

@Component({
	components: {
		SeletorDeLojas,
	},
})

export default class DialogoDeEdicaoDeMarca extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({type: Object}) marca?: Marca
	lojasSelecionadas: Loja[] = []
	marcaEmEdicao: Marca = { id: '', nome: '', lojas: [] } 
	mostra = false
	salvando = false

	saveMarcaUseCase = new SaveMarcaUseCase()

	obrigatorio = obrigatorio

	mostrar() {		
		this.onChangeMarca()
		this.mostra = true
	}

	esconder() {
		this.marcaEmEdicao = { id: '', nome: '', lojas: [] } 
		this.lojasSelecionadas = []
		this.mostra = false
	}

	async salvar() {
		if (!this.marca) return
		if (!this.marcaEmEdicao) return
		if (!this.form.validate()) return

		try {
			this.salvando = true 
			
			this.editarMarca()
			
			const marcaSalva = !this.marca.id
				? await this.saveMarcaUseCase.create(this.marca)
				: await this.saveMarcaUseCase.update(this.marca)

			this.$emit('confirmado', marcaSalva)
			AlertModule.setSuccess('Marca salva com sucesso')
		} catch(error) {
			AlertModule.setError(error.response.data[0])
		} finally {
			this.salvando = false
			this.mostra = false
		}
	}

	async adicionarParametro(idLojaNova: string, indice: any) {
		// const marcaId  = this.marca.id != null ? this.marca.id : null
		let podeAdicionar: boolean | true = true
		
		const lojasDaLista = this.lojasSelecionadas !== null && this.lojasSelecionadas.length > 1 ? this.lojasSelecionadas.filter(
			loja =>
				loja.id === idLojaNova,
		) : []

		if (lojasDaLista.length > 1) {
			AlertModule.setError(
				'Essa loja já está selecionada',
			)
			podeAdicionar = false
			this.removerLojaDaMarca(indice)
			return
		}
		
		const lojaSelecionada: Loja = await new FindLojaUseCase().findLojaById(idLojaNova)

		if (podeAdicionar) {
			idLojaNova != null
				? this.lojasSelecionadas[indice] = lojaSelecionada
				: null
				
		}
	}

	removerLojaDaMarca(indice: any) {
		if (!this.lojasSelecionadas) return
		this.lojasSelecionadas.splice(indice, 1)
	}


	adicionarLoja() {
		this.lojasSelecionadas.push([] as unknown as Loja)
	}

	editarMarca() {
		if(this.marca && this.marcaEmEdicao) {
			this.marca.nome = this.marcaEmEdicao.nome
			this.marca.lojas = this.lojasSelecionadas
		}
	}

	@Watch('marca')
	onChangeMarca() {			
		this.marcaEmEdicao.nome = this.marca ? this.marca.nome : ''
		this.marcaEmEdicao.lojas = this.marca ? this.marca.lojas : []
		this.lojasSelecionadas = this.marca ? this.marca.lojas : []
	}
}
